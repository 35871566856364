import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() color: string = 'success';
  @Input() close: boolean = true;
  @Input() remember: boolean = false;
  @Input() name: string = null;

  visible:boolean = true;

  constructor(
  ) { }

  ngOnInit() {
    if (this.name) {
      let data = this.getData();
      this.visible = !data[this.name]; 
    }
  }

  onClose() {
    this.visible = false;
    this.remmemberClose();
  }

  getData() {
    try {
      let val = localStorage.getItem('alerts');
      if (val) {
        return JSON.parse(val);
      } else {
        return {};
      }
    } catch(e) {
      return {};
    }
  }

  remmemberClose() {
    if (this.name && this.remember) {
      let data = this.getData();
      data[this.name] = true;
      try{
        localStorage.setItem('alerts', JSON.stringify(data));
      } catch(e) {
        console.log('Error saving localstorage')
      }
    }
  }

}
