import { User } from './../models/User';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Location } from '@angular/common';




@Injectable({
  providedIn: 'root'
})
export class FileServer {
  protected fs_images = environment.fileserver.images;

  constructor(
    protected location : Location
  ) { }

  getImage(path) {
    return this.location.normalize(this.fs_images+path);
  }

  getAvatar(user:User|any, size=null) {
    if (!user.avatar) {
      return '';
    }
    let path = user.avatar;
    if (path) { 
      if (size) {
        path = path.replace('/avatar.', `/${size}.`);
      }
      return this.location.normalize(this.fs_images+path);
    }
  }


}
