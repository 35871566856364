import { FileUploadInput } from './../../components/forms/fileupload-field/fileupload-field.component';
import { AvailabilityComponent } from './../../components/misc/availability/availability.component';
import { AlertComponent } from './../../components/misc/alert/alert.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { AvatarModule } from 'ngx-avatar';
import { MomentModule } from 'ngx-moment';
import { NgxUploaderModule } from 'ngx-uploader';
import { ValidatorsModule } from 'ngx-validators';
import { ConfirmDialog } from 'src/app/components/dialogs/confirmdialog/confirm-dialog.component';
import { AutocompleteChipsInput } from 'src/app/components/forms/autocomplete-chips/autocomplete-chips.component';
import { HasPermsDirective } from 'src/app/directives/hasperms.directive';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { environment } from 'src/environments/environment';
import { EditUserDialog } from '../../components/dialogs/userdialog/user-dialog.component';
import { EditUserWithLoginDialog } from '../../components/dialogs/userwithlogindialog/user-with-login-dialog.component';
import { AvatarComponent } from '../../components/misc/avatar/avatar.component';
import { AvailabilityFieldInput } from '../../components/forms/availability-field/availability-field.component';
import { DropDownMenuComponent } from './../../components/menus/dropdownmenu/dropdownmenu.component';
import { HasNotPermsDirective } from './../../directives/hasnotperms.directive';
import { SecurePipe } from './../../pipes/secure.pipe';
import { AvailabilityDialog } from 'src/app/components/dialogs/availabilitydialog/availability-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { AddItemDialog } from 'src/app/components/dialogs/additemdialog/add-item-dialog.component';
import { UsersOrderDialog } from 'src/app/components/dialogs/usersorderdialog/users-order-dialog.component';

@NgModule({
    declarations: [
        DropDownMenuComponent,
        ConfirmDialog,
        AddItemDialog,
        EditUserDialog,
        UsersOrderDialog,
        EditUserWithLoginDialog,
        HasPermsDirective,
        HasNotPermsDirective,
        AvatarComponent,
        SecurePipe,
        SafePipe,
        AutocompleteChipsInput,
        AvailabilityDialog,
        AvailabilityFieldInput,
        AlertComponent,
        AvailabilityComponent,
        FileUploadInput
    ],
    imports: [
        LayoutModule,
        CommonModule,
        ValidatorsModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatMenuModule,
        MatToolbarModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatBadgeModule,
        FormsModule,
        MatSliderModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        TranslateModule,
        MatDialogModule,
        MatAutocompleteModule,
        DragDropModule,
        MomentModule,
        AvatarModule,
        NgxUploaderModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatProgressButtonsModule,
        FlexLayoutModule,
        Nl2BrPipeModule
    ],
    exports: [
        CommonModule,
        LayoutModule,
        ValidatorsModule,
        MatButtonModule,
        MatBadgeModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatMenuModule,
        MatSelectModule,
        MatCardModule,
        MatToolbarModule,
        MatInputModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatNativeDateModule,
        MatExpansionModule,
        FormsModule,
        MatSliderModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        DropDownMenuComponent,
        ConfirmDialog,
        AddItemDialog,
        EditUserDialog,
        EditUserWithLoginDialog,
        MatDialogModule,
        TranslateModule,
        HasPermsDirective,
        HasNotPermsDirective,
        MatAutocompleteModule,
        DragDropModule,
        MomentModule,
        AvatarModule,
        NgxUploaderModule,
        AvatarComponent,
        SecurePipe,
        SafePipe,
        MatSnackBarModule,
        AutocompleteChipsInput,
        MatCheckboxModule,
        MatProgressButtonsModule,
        AvailabilityDialog,
        AvailabilityFieldInput,
        AlertComponent,
        AvailabilityComponent,
        Nl2BrPipeModule,
        FileUploadInput,
        FlexLayoutModule
    ]
})
export class SharedModule {}
