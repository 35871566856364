import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AuthenticationService } from './../services/authentication.service';
import { SafePipe } from './safe.pipe';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private auth: AuthenticationService
  ) { }

  transform(url: string) {

    return new Observable<SafeUrl>((observer) => {

      // This is a tiny blank image
      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

      // The next and error callbacks from the observer
      const { next, error } = observer;

      this.http.get(url, { responseType: 'blob' }).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          let res = reader.result + '';
          let pipe = new SafePipe(this.sanitizer);
          observer.next(pipe.transform(res, "url"));
        };
      });

      return { unsubscribe() { } };
    });
  }
}