import { NavigationService } from 'src/app/services/navigation.service';
import { AuthenticationService } from './../services/authentication.service';
import { take, map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  
  constructor(
      private router: Router, 
      private auth: AuthenticationService,
      private nav: NavigationService) {}
  

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {


    return this.auth.currentUser.pipe(
      take(1),
      map((user) => {
        let auth = this.auth.getCurrentAuthUser();

        if (next.routeConfig.data && next.routeConfig.data.roles) {
          return auth.exists() && auth.hasRole(next.routeConfig.data.roles);
        }
        return auth.exists();
      }),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/auth/login']);
          return false;
        }
        if (next.routeConfig.data && next.routeConfig.data.title) {
          this.nav.setTitle(next.routeConfig.data.title);
        } else {
          this.nav.setTitle(null);
        }

        return true;
      })
    )
  }


  canActivateOLD(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {


    return this.auth.currentAuthUser.pipe(
      take(1),
      map((user) => {
        if (next.routeConfig.data && next.routeConfig.data.roles) {
          return user.exists() && user.hasRole(next.routeConfig.data.roles);
        }
        return user.exists();
      }),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/auth/login']);
          return false;
        }
        if (next.routeConfig.data && next.routeConfig.data.title) {
          this.nav.setTitle(next.routeConfig.data.title);
        } else {
          this.nav.setTitle(null);
        }

        return true;
      })
    )
  }
}
