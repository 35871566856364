import { FileServer } from '../../../services/fileserver.service';
import { User } from '../../../models/User';
import { Component, OnInit, Input, OnDestroy} from '@angular/core';

let colors = {
  'A':'#A8D878',
  'B':'#78D878',
  'C':'#78D8A8',
  'D':'#78D8D8',
  'E':'#78A8D8',
  'F':'#78D8BE',
  'G':'#78C2D8',
  'H':'#7892D8',
  'I':'#8E78D8',
  'J':'#BE78D8',
  'K':'#D878C2',
  'L':'#617FC0',
  'M':'#7261C0',
  'N':'#A261C0',
  'O':'#C061AF',
  'P':'#C0617F',
  'Q':'#9B61C0',
  'R':'#C061B5',
  'S':'#C06186',
  'T':'#C06B61',
  'U':'#C061BA',
  'V':'#C0618A',
  'W':'#8AC061',
  'Y':'#C06179',
  'Z':'#79C061',
  '0':'#DFAAF4',
  '1':'#EE8CA5',
  '2':'#EEA48C',
  '3':'#EED58C',
  '4':'#D6EE8C',
  '5':'#D5AAF4',
  '6':'#F4AAEE',
  '7':'#F4B0AA',
  '8':'#F4D5AA',
  '9':'#F4AAC9'
};

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit,OnDestroy {
  @Input() size : string = "sm";
  @Input() set user(user:User) {    
    if(user){
      this.text="";
      this._user = user;
      //get initials + set bg color
      this.initializeData();
    }  
  }
  
  public text = "";
  public _user = null;
  public color = 'none';
  public fontSize = '20px';
  public showInput = false;

  constructor(public fs : FileServer) { }

  ngOnInit() { }

  ngOnDestroy(){
    this._user = null;
  }

  initializeData(){   
    this.getInitials();
    //set bg color from the colors object
    this.color = colors[this.text[0]];
  }

  getInitials(){
    //get the alias and split
    let names = this._user.getIdentity().split(' ',3);
    let letter_count = 0;
    //get first letter of every name
    names.forEach(name => {
      this.text += name[0].toLocaleUpperCase();
      letter_count++;
    });

    this.setFontSize(letter_count);
  }

  setFontSize(count){
    if((count == 1  || count == 2 ) && this.size == "xs"){
      this.fontSize = '12px';
    }
    else if(count == 3 && this.size == "xs"){
      this.fontSize = '10px'; 
    }
    else if(this.size == "sm"){
      this.fontSize = '18px';
    }
    else if(this.size == "md"){
      this.fontSize = '20px';
    }
    else if(this.size == "lg"){
      this.fontSize = '60px';
    }
    else if(this.size == "xl"){
      this.fontSize = '70px';
    }
  }
}
