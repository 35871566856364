import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BCF Life Sciences';
  authenticated: boolean = false;

  constructor(private auth: AuthenticationService, private router: Router) {
    this.auth.currentAuthUser.subscribe(user => {
      this.authenticated = user.exists();
    });

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    });
  }
}
