import * as _ from 'lodash';

interface ValueListItem {
    id: number;
    value_list_id: number;
    label: string;
    value: any;
    order: number;
    added: string;
};

class ValueList {
    id: number = null;
    listname: string = null;
    label: string = null;
    lang: string = null;
    category: string = null;
    items: any[] = [];

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (this.hasOwnProperty(key)) {
                this[key] = data[key];

            }
        }
    }

    getSelected(arr: any[]) {
        let ret = [];
        if (arr) {
            arr.forEach(n => {
                let o = _.find(this.items, { 'value': n });
                if (o) {
                    ret.push(o.label);
                } else {
                    ret.push(n);
                }

            });
        }
        return ret;
    }


};

export {
    ValueList, ValueListItem
}