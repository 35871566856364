import * as _ from 'lodash';

export class User {
    id: number = null;
    complete : boolean = false;
    alias: string = null;
    avatar: string = null;
    firstname: string = null;
    lastname: string = null;
    email: string = null;
    login: string = null;
    gender: string = null;
    mobile: string = null;
    created_at: string = null;
    email_verified: boolean = false;
    phone_verified: boolean = false;
    last_login: string = null;
    subscribed: boolean = null;
    activeSubscription: boolean = null;
    notification_hash: string = null;
    birthdate: string = null;
    profile_complete:boolean = null;
    notifications_email: boolean = null;
    notifications_fcm: boolean = null;
    roles: string[] = null;

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (this.hasOwnProperty(key)) {
                this[key] = data[key];

            }
        }
    }

    getIdentity(mode = 'alias') {
        if (mode == 'alias' && this.alias) {
            return this.alias
        }
        return `${this.firstname} ${this.lastname}`;
    }

    hasActiveSubscription() {
        return this.subscribed && this.activeSubscription;
    }

    isVerified(mode = null): boolean {
        switch (mode) {
            case 'phone':
                return this.phone_verified;
            case 'email':
                return this.email_verified;
            default:
                return this.phone_verified && this.email_verified;
        }
    }

    hasRole(roles=[]) {
        if (!this.roles || this.roles.length==0) {
            return false;
        }
        return _.intersection(this.roles, roles).length>0;
    }


};

export interface UserSubscription {
    id: number;
    uid: number;
    description: string;
    price: number;
    stripe_id: string;
    stripe_plan: string;
    trial_ends_at: string;
    ends_at: string;
    canceled_at: string;
    created_at: string;
    deleted_at: string
}; 