import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'users-order-dialog',
  templateUrl: './users-order-dialog.component.html',
  styleUrls: ['./users-order-dialog.component.scss']
})
export class UsersOrderDialog implements OnInit {
  public users: { firstname: string, lastname: string }[] = [];

  constructor(
    public dialogRef: MatDialogRef<UsersOrderDialog>,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    if (this.data && this.data.users.length) {
      this.data.users.forEach(u => {
        this.users.push({
          firstname: u.firstname,
          lastname: u.lastname
        })
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteOrders() {
    this.dataService
      .postAsPromise('menu/delete_orders', { date: this.data.selectedDate })
      .then((result: any) => {
        if (result) {
          this.dialogRef.close({ result });
        }
      }).catch((error: any) => {
        console.log(error);
      });
  }
}
