import * as _ from 'lodash';

export class AuthUser {
    access_token: string = null;
    expires_in: number = null;
    token_type: string = null;
    roles: Array<string>=[];

    constructor( data : Object=null) {
        if (data) {
            this.import(data);
        } 
    }

    exists() {
        return !!this.access_token;
    }

    import(data) {
        for (var key in data) {
            if (this.hasOwnProperty(key)) {
                this[key] = data[key];
                
            }
        }
    }

    hasRole(roles=[]) {
        if (!this.roles || this.roles.length==0) {
            return false;
        }
        return _.intersection(this.roles, roles).length>0;
    }


};