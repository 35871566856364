import { EditUserDialog } from './../../dialogs/userdialog/user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthUser } from '../../../models/AuthUser';
import { User } from '../../../models/User';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss']
})
export class LoggedLayoutComponent implements OnInit {
  user : User = null;
  authUser : AuthUser = null;
  sideMenu =[];
  profileMenu = [];
  ready = false;


  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public navService: NavigationService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.auth.currentAuthUser.subscribe(user=>{
      this.authUser = user;
      this.sideMenu = this.navService.getMenu('main');
      this.profileMenu = this.navService.getMenu('profile');
      this.ready = true;
   
    })
    this.auth.currentUser.subscribe(user=>{
      this.user = user;
    })
  }

  ngOnInit() {
  }
  
  isItemVisible(item=null) {
    if (item && item.roles) {
      return this.authUser && this.authUser.hasRole(item.roles)
    }
    return true;
  }

  goToProfile() {
    // if (this.authUser.hasRole(['babysitter'])) {
    //   this.router.navigate(['babysitter','profile']);

    // } else if (this.authUser.hasRole(['nanny'])) {
    //   this.router.navigate(['nanny','profile']);
    // } else {
    //   this.router.navigate(['family','profile']);
    // }
  }

  goToAccount() {
      let title = this.translate.instant('USER.MY_PROFILE');
      let user = this.auth.getCurrentUser();
      const dialogRef = this.dialog.open(EditUserDialog, {
        width: '550px',
        data: {
          title: title,
          item: user,
          me :true
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.auth.loadCurrentUser();
      });
  }

  
}
