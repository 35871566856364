import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import "moment/locale/fr";
import { AvailabilityData } from './../../forms/availability-field/availability-field.component';


@Component({
  selector: 'availability-dialog',
  templateUrl: './availability-dialog.component.html',
  styleUrls: ['./availability-dialog.component.scss'],

})
export class AvailabilityDialog implements OnInit {
  protected server_errors = null;
  protected save_clicked = false;
  public saveLabel: string = null;
  public cancelLabel: string = null;
  public days_of_week;


  public availability_array = {
    'before_school': [0, 0, 0, 0, 0, 0, 0],
    'morning': [0, 0, 0, 0, 0, 0, 0],
    'noon': [0, 0, 0, 0, 0, 0, 0],
    'afternoon': [0, 0, 0, 0, 0, 0, 0],
    'after_school': [0, 0, 0, 0, 0, 0, 0],
    'evening': [0, 0, 0, 0, 0, 0, 0],
    'night': [0, 0, 0, 0, 0, 0, 0],
  }


  constructor(
    public dialogRef: MatDialogRef<AvailabilityDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private translate: TranslateService
  ) {

    this.days_of_week = moment.weekdaysShort();

    this.cancelLabel = data.cancelButton || this.translate.instant('BUTTONS.BT_CANCEL');
    this.saveLabel = data.saveButton || this.translate.instant('BUTTONS.BT_VALID');

    if (this.data.availability ) {
      let av: AvailabilityData = data.availability;

      for (let i in av) {
        for (let p = 0; p < 7; p++) {
          let pow = Math.pow(2, p);
          if ((av[i] & pow) == pow) {
            this.availability_array[i][p] = 1;
          }
        }

      }
    }


  }

  ngOnInit() {


  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange(event, index, item) {
    let check;
    if (event.checked) {
      check = 1;
    } else {
      check = 0;
    }

    this.availability_array[item][index] = check;
  }

  availabilitySave() {
    this.save_clicked = true;

    let ret: AvailabilityData = {
      before_school: 0,
      morning: 0,
      noon: 0,
      afternoon: 0,
      after_school: 0,
      evening: 0,
      night: 0,
    }
    for (let i in this.availability_array) {
      this.availability_array[i].forEach((a, index) => {
        if (a == 1) {
          ret[i] += Math.pow(2, (index));
        }
      });
    }
    this.data.availability = ret;
    this.dialogRef.close('bt_save');
  }

  array(n: number): any[] {
    return Array(n);
  }

}
