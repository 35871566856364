import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.scss']
})
export class AddItemDialog implements OnInit {
  foodTitle: string = null;
  addItemForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.addItemForm = this.formBuilder.group({
      foodTitle: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  saveForm() {
    if (this.addItemForm.valid) {
      this.dialogRef.close(this.addItemForm.value.foodTitle);
    }
  }
}
