import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'register',
    redirectTo: 'auth/register'
  },
  {
    path: 'register/:token',
    redirectTo: 'auth/register/:token'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { xroles: ['super-admin'], title: 'STATIC.NAV.DASHBOARD' },
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.ADMIN' },
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'caterer',
    canActivate: [AuthGuard],
    data: { roles: ['caterer', 'super-admin'], title: 'STATIC.NAV.CATERER.MENU' },
    loadChildren: () => import('./modules/caterer/caterer.module').then(mod => mod.CatererModule)
  },
  {
    path: 'employee',
    canActivate: [AuthGuard],
    data: { roles: ['employee', 'multi'], title: 'STATIC.NAV.EMPLOYEE.MENU' },
    loadChildren: () => import('./modules/employee/employee.module').then(mod => mod.EmployeeModule)
  },
  // {
  //   path: 'multi',
  //   canActivate: [AuthGuard],
  //   data: { roles: ['multi'], title: 'STATIC.NAV.MULTI.MENU' },
  //   loadChildren: () => import('./modules/multi/multi.module').then(mod => mod.MultiModule)
  // },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(mod => mod.ErrorsModule)
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
