import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ValueList } from '../models/ValueList';

export interface ListApi {
  data: any[];
  meta: any;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  protected api_root = environment.api.root;
  protected data ={ lists: {} };
  protected loaded_categories ={ };
  

  constructor(
    private http: HttpClient
  ) { }

  getAsPromise(endpoint, params={}):any {
    return new Promise((resolve, reject) => {
      this.http
        .get<any[]>(`${this.api_root}/${endpoint}`, {params : params})
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject(error)
        });
    });
  }

  getList(endpoint, params={}): Observable<ListApi> {
    return this.http.get<ListApi>(`${this.api_root}/${endpoint}`, {params:params});
  }

  get(endpoint, params={}): Observable<any> {
    return this.http.get(`${this.api_root}/${endpoint}`, {params:params});
  }

  delete(endpoint, params={}): Observable<any> {
    return this.http.delete(`${this.api_root}/${endpoint}`, {params:params});
  }

  putAsPromise(endpoint, data): Promise<any> {
    return new Promise((resolve, reject)=>{
      this.http
        .put(`${this.api_root}/${endpoint}`, data)
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject( this.parseErrors(error))
        });
      });
  }

  postAsPromise(endpoint, data): Promise<any> {
    return new Promise((resolve, reject)=>{
      this.http
        .post(`${this.api_root}/${endpoint}`, data)
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject( this.parseErrors(error))
        });
      });
  }

  deleteAsPromise(endpoint, data=null): Promise<any> {
    if (!data) {
      data={};
    }
    return new Promise((resolve, reject)=>{
      this.http
        .delete(`${this.api_root}/${endpoint}`, {params:data})
        .toPromise()
        .then(res => {
          resolve(res);
        }).catch(error => {
          reject( this.parseErrors(error))
        });
      });
  }

  parseErrors(data) {
    let ret=data.error;
    ret.fields = {};
    if  (data.error.errors && data.error.errors.length>0) {
      data.error.errors.forEach( err => {
        ret.fields[err.field] = err.code;
      });
    }
    return ret;
  }

  getLists(category) : Promise<boolean> {

    return new Promise((resolve, reject)=>{
      if (this.loaded_categories[category]) {
        resolve( true );
        return;
      }

      this.get(`lists/category/${category}`,{})
        .toPromise()
        .then(res=>{
          if(res.data) {
            res.data.forEach(l => {
              let list = new ValueList(l);
              this.data.lists[list.listname] = list;
            });
          }
          this.loaded_categories[category] = true;
          resolve( true );
        })
        .catch(err=>{
          reject( false );
        })

    
    });
  }
  getListData(name): Promise<ValueList> {

    return new Promise((resolve, reject)=>{
      if (this.data.lists[name]) {
        resolve( this.data.lists[name]);
        return;
      }

      this.get(`lists/${name}`,{})
        .toPromise()
        .then(res=>{
          let list = new ValueList(res.data);
          this.data.lists[list.listname] = list;
          resolve( list );
        })
        .catch(err=>{
          resolve(new ValueList() )
        })
    });
  }

  getPdfFile(endpoint) {

    return new Promise((resolve, reject) => {
      this.http.get(`${this.api_root}/${endpoint}`, { 
       headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        }), responseType: 'blob'})
        .toPromise()
        .then(data => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = window.URL.createObjectURL(file);
          window.open(fileURL);
          resolve(true);
        }).catch(error => {
          reject(this.parseErrors(error))
        });
     
    });
  }

  downloadFile(endpoint, mime, filename=null) {

    return new Promise((resolve, reject) => {
      this.http.get(`${this.api_root}/${endpoint}`, { 
       headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        }), responseType: 'blob'})
        .toPromise()
        .then(data => {
          const file = new Blob([data], { type: mime });

          const element = document.createElement('a');
          element.href = URL.createObjectURL(file);
          if (filename) {
            element.download = filename;
          }
          document.body.appendChild(element);
          element.click();

          // const fileURL = window.URL.createObjectURL(file);
          // window.open(fileURL);
          resolve(true);
        }).catch(error => {
          reject(this.parseErrors(error))
        });
     
    });
  }

}
